import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives } from "vue"
import _imports_0 from '@/assets/icons/navigate_right_black_24px.png'
import _imports_1 from '@/assets/icons/white_arrow_right_24px.png'
import _imports_2 from '@/assets/icons/white_publish_24px.png'
import _imports_3 from '@/assets/image/logo_myculture.png'
import _imports_4 from '@/assets/icons/navigate_left_black_24px.png'
import _imports_5 from '@/assets/icons/white_navigate_back_24px.png'
import _imports_6 from '@/assets/icons/more_vert_black_24dp.png'
import _imports_7 from '@/assets/icons/delete_20px@2x.png'


const _hoisted_1 = {
  key: 0,
  class: "body-width modal"
}
const _hoisted_2 = { class: "box-card" }
const _hoisted_3 = { class: "modal-title" }
const _hoisted_4 = { class: "modal-body" }
const _hoisted_5 = { class: "modal-footer" }
const _hoisted_6 = {
  key: 0,
  src: _imports_0,
  alt: "icons_white_arrow_right_24px",
  class: "icon-16 ml-16"
}
const _hoisted_7 = {
  key: 1,
  src: _imports_1,
  alt: "icons_white_arrow_right_24px",
  class: "icon-16 ml-16"
}
const _hoisted_8 = {
  key: 2,
  class: "btn btn-create btn-progress btn-primary disable-true"
}
const _hoisted_9 = {
  key: 1,
  class: "menu-container flex-center"
}
const _hoisted_10 = { class: "body-width-reponsive-max" }
const _hoisted_11 = {
  class: "nav-bar-container flex-center",
  style: {"flex-direction":"row"}
}
const _hoisted_12 = {
  class: "flex-center",
  style: {"width":"30%"}
}
const _hoisted_13 = { class: "label-container" }
const _hoisted_14 = { class: "nav-text-header" }
const _hoisted_15 = { style: {"width":"70%","height":"50px","flex-basis":"100%","display":"flex","align-items":"center","place-content":"flex-end"} }
const _hoisted_16 = { class: "fs-12 fw-700" }
const _hoisted_17 = { class: "fs-12 fw-700" }
const _hoisted_18 = {
  key: 0,
  src: _imports_0,
  class: "icon-20 ml-12",
  alt: "navigate_right_black_24px"
}
const _hoisted_19 = {
  key: 1,
  src: _imports_1,
  class: "icon-20 ml-12",
  alt: "icons_white_arrow_right_24px"
}
const _hoisted_20 = {
  key: 1,
  width: "3rem",
  height: "3rem",
  version: "1.1",
  id: "L9",
  xmlns: "http://www.w3.org/2000/svg",
  "xmlns:xlink": "http://www.w3.org/1999/xlink",
  x: "0px",
  y: "0px",
  viewBox: "0 0 100 100",
  "enable-background": "new 0 0 0 0",
  "xml:space": "preserve"
}
const _hoisted_21 = { class: "fs-12 fw-700" }
const _hoisted_22 = { class: "ml-24 flex-mid" }
const _hoisted_23 = {
  key: 3,
  class: "ml-16 btn02 btn-40 disable-true"
}
const _hoisted_24 = { class: "fs-12 fw-700 mr-16" }
const _hoisted_25 = {
  key: 2,
  class: "body-width",
  id: "assessment_setup_values_and_traits"
}
const _hoisted_26 = { style: {"margin-top":"6.4rem"} }
const _hoisted_27 = {
  class: "body-container-with-step",
  style: {"margin-top":"6.4rem"}
}
const _hoisted_28 = { class: "step-menu-container vertical" }
const _hoisted_29 = ["onClick"]
const _hoisted_30 = { class: "menu-inner" }
const _hoisted_31 = { class: "step-num" }
const _hoisted_32 = { class: "step-name" }
const _hoisted_33 = {
  key: 0,
  class: "step-desc"
}
const _hoisted_34 = {
  class: "",
  style: {"display":"flex","justify-content":"center"}
}
const _hoisted_35 = {
  key: 0,
  class: "x-card-question-padding box-card mb-32 bg-FFFFFF",
  style: {"min-height":"420px"}
}
const _hoisted_36 = {
  class: "max-width-container",
  style: {"width":"872px"}
}
const _hoisted_37 = {
  key: 0,
  class: "fs-24 fc-1B1C1E fw-900"
}
const _hoisted_38 = {
  key: 1,
  class: "fs-16 mt-12"
}
const _hoisted_39 = { class: "" }
const _hoisted_40 = { style: {"x-margin-top":"6rem"} }
const _hoisted_41 = {
  class: "flex-center",
  style: {"padding":"30px 20px"}
}
const _hoisted_42 = { class: "fc-primary fs-16 fw-700" }
const _hoisted_43 = { class: "x-mt-16" }
const _hoisted_44 = { class: "scenarios-container" }
const _hoisted_45 = {
  class: "relative",
  style: {"width":"16px","height":"16px","top":"3px"}
}
const _hoisted_46 = {
  width: "7",
  height: "11",
  viewBox: "0 0 7 11",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  style: {"margin":"2.5px 4px"}
}
const _hoisted_47 = {
  class: "index flex-mid fc-primary",
  style: {"width":"2.4rem"}
}
const _hoisted_48 = { class: "flex-center" }
const _hoisted_49 = { class: "content" }
const _hoisted_50 = ["onClick"]
const _hoisted_51 = {
  key: 1,
  class: "card-question-padding box-card mb-32 bg-FFFFFF",
  style: {"min-height":"420px"}
}
const _hoisted_52 = { class: "max-width-container" }
const _hoisted_53 = { class: "fs-24 fc-1B1C1E fw-900" }
const _hoisted_54 = { class: "fs-16 mt-12" }
const _hoisted_55 = { class: "" }
const _hoisted_56 = { class: "mt-18" }
const _hoisted_57 = {
  class: "tutorial",
  style: {"margin-top":"6rem"}
}
const _hoisted_58 = { class: "select-ab-btn active color-15CE95 flex-center" }
const _hoisted_59 = { class: "text-description flex-center" }
const _hoisted_60 = { class: "select-ab-btn active color-FAA13A flex-center" }
const _hoisted_61 = { class: "text-description flex-center" }
const _hoisted_62 = { class: "select-ab-btn active color-FA8B3A flex-center" }
const _hoisted_63 = { class: "text-description flex-center" }
const _hoisted_64 = { class: "select-ab-btn active color-FA5F3A flex-center" }
const _hoisted_65 = { class: "text-description flex-center" }
const _hoisted_66 = { class: "select-ab-btn active color-FA3A3A flex-center" }
const _hoisted_67 = { class: "text-description flex-center" }
const _hoisted_68 = {
  key: 2,
  class: "card-question-padding box-card mb-32 bg-FFFFFF",
  style: {"min-height":"420px"}
}
const _hoisted_69 = { key: 0 }
const _hoisted_70 = { class: "fs-24 fc-1B1C1E fw-900" }
const _hoisted_71 = ["innerHTML"]
const _hoisted_72 = { class: "scenario-value fc-primary" }
const _hoisted_73 = { class: "scenario-label" }
const _hoisted_74 = ["onClick"]
const _hoisted_75 = { class: "value-box flex-mid" }
const _hoisted_76 = {
  key: 3,
  class: "ab-container"
}
const _hoisted_77 = { class: "inner" }
const _hoisted_78 = { class: "scenario-title-wrapper" }
const _hoisted_79 = { class: "scenario-title" }
const _hoisted_80 = { class: "scenario-desc" }
const _hoisted_81 = { class: "radio-group" }
const _hoisted_82 = { class: "x-axis-line" }
const _hoisted_83 = { class: "label-container" }
const _hoisted_84 = ["onClick"]
const _hoisted_85 = { class: "icon" }
const _hoisted_86 = ["onClick"]
const _hoisted_87 = ["fill"]
const _hoisted_88 = {
  key: 3,
  class: "body-width modal"
}
const _hoisted_89 = { class: "box-card" }
const _hoisted_90 = { class: "modal-title" }
const _hoisted_91 = { class: "modal-body" }
const _hoisted_92 = { class: "modal-footer" }
const _hoisted_93 = { class: "remove-header remove-padding-modal" }
const _hoisted_94 = { class: "pb-42 pt-42 pl-32 pr-52 fc-1B1C1E word-break-normal" }
const _hoisted_95 = { class: "fs-20 fw-700" }
const _hoisted_96 = {
  class: "fs-14 mt-20",
  style: {"color":"#696c80"}
}
const _hoisted_97 = {
  key: 0,
  class: "input-container-02 mt-20"
}
const _hoisted_98 = ["placeholder"]
const _hoisted_99 = { class: "label" }
const _hoisted_100 = {
  key: 1,
  class: "input-container-02 mt-20"
}
const _hoisted_101 = ["placeholder"]
const _hoisted_102 = { class: "label" }
const _hoisted_103 = { class: "el-dialog-footer flex-center mt-32" }
const _hoisted_104 = { class: "pb-42 pt-42 pl-32 pr-52 fc-1B1C1E word-break-normal" }
const _hoisted_105 = { class: "fs-18 fw-700" }
const _hoisted_106 = { class: "fs-15 mt-20" }
const _hoisted_107 = { class: "fs-15 fw-700 mt-20" }
const _hoisted_108 = ["placeholder"]
const _hoisted_109 = { class: "fs-15 fw-700 mt-16" }
const _hoisted_110 = ["placeholder"]
const _hoisted_111 = { class: "flex-center mt-32" }
const _hoisted_112 = {
  key: 4,
  class: "menu-container flex-center fixed"
}
const _hoisted_113 = { class: "body-width flex-center" }
const _hoisted_114 = {
  class: "mr-auto",
  style: {"width":"18rem"}
}
const _hoisted_115 = ["src"]
const _hoisted_116 = {
  key: 1,
  src: _imports_3,
  alt: "Happily logo",
  class: "company-logo-image",
  style: {"width":"18rem"}
}
const _hoisted_117 = {
  key: 0,
  src: _imports_0,
  alt: "icons_white_arrow_right_24px",
  class: "icon-24 ml-16"
}
const _hoisted_118 = {
  key: 1,
  src: _imports_1,
  alt: "icons_white_arrow_right_24px",
  class: "icon-24 ml-16"
}
const _hoisted_119 = {
  key: 1,
  class: "ml-auto flex-center"
}
const _hoisted_120 = {
  key: 0,
  src: _imports_4,
  alt: "navigate_left_black_24px",
  class: "icon-24 mr-16"
}
const _hoisted_121 = {
  key: 1,
  src: _imports_5,
  alt: "white_navigate_back_24px",
  class: "icon-24 mr-16"
}
const _hoisted_122 = {
  key: 0,
  src: _imports_0,
  alt: "icons_white_arrow_right_24px",
  class: "icon-24 ml-16"
}
const _hoisted_123 = {
  key: 1,
  src: _imports_1,
  alt: "icons_white_arrow_right_24px",
  class: "icon-24 ml-16"
}
const _hoisted_124 = {
  key: 2,
  class: "ml-auto flex-center"
}
const _hoisted_125 = {
  key: 0,
  src: _imports_4,
  alt: "navigate_left_black_24px",
  class: "icon-24 mr-16"
}
const _hoisted_126 = {
  key: 1,
  src: _imports_5,
  alt: "white_navigate_back_24px",
  class: "icon-24 mr-16"
}
const _hoisted_127 = {
  key: 0,
  src: _imports_0,
  alt: "icons_white_arrow_right_24px",
  class: "icon-24 ml-16"
}
const _hoisted_128 = {
  key: 1,
  src: _imports_1,
  alt: "icons_white_arrow_right_24px",
  class: "icon-24 ml-16"
}
const _hoisted_129 = {
  key: 5,
  class: "mock-menu-container"
}
const _hoisted_130 = {
  key: 6,
  class: "body-width",
  style: {"padding-top":"6rem"}
}
const _hoisted_131 = {
  key: 0,
  class: "box-card mb-32 center bg-FFFFFF",
  style: {"min-height":"420px","padding":"8rem 3.2rem 3.2rem"}
}
const _hoisted_132 = { class: "max-width-520 mx-auto" }
const _hoisted_133 = { class: "fs-24 fc-1B1C1E fw-900" }
const _hoisted_134 = { class: "mt-12 fs-16" }
const _hoisted_135 = { class: "mt-32 fs-16" }
const _hoisted_136 = {
  key: 0,
  src: _imports_0,
  alt: "icons_white_arrow_right_24px",
  class: "icon-16 ml-16"
}
const _hoisted_137 = {
  key: 1,
  src: _imports_1,
  alt: "icons_white_arrow_right_24px",
  class: "icon-16 ml-16"
}
const _hoisted_138 = {
  key: 2,
  class: "btn btn-create btn-DEDEDE btn-50 btn-primary mx-auto",
  style: {"width":"16.6rem","margin-top":"6.4rem"}
}
const _hoisted_139 = {
  key: 1,
  class: "card-question-padding box-card mb-32 bg-FFFFFF",
  style: {"min-height":"420px"}
}
const _hoisted_140 = { class: "max-width-container" }
const _hoisted_141 = { class: "fs-24 fc-1B1C1E fw-900" }
const _hoisted_142 = { class: "fs-16 mt-12" }
const _hoisted_143 = { class: "" }
const _hoisted_144 = { style: {"margin-top":"6rem"} }
const _hoisted_145 = { class: "flex-center" }
const _hoisted_146 = { class: "fc-primary fs-16 fw-700" }
const _hoisted_147 = { class: "mt-16" }
const _hoisted_148 = { class: "scenarios-container" }
const _hoisted_149 = {
  class: "index flex-mid fc-primary",
  style: {"width":"2.4rem"}
}
const _hoisted_150 = { class: "flex-center" }
const _hoisted_151 = { class: "content" }
const _hoisted_152 = ["onClick"]
const _hoisted_153 = {
  key: 2,
  class: "card-question-padding box-card mb-32 bg-FFFFFF",
  style: {"min-height":"420px"}
}
const _hoisted_154 = { class: "max-width-container" }
const _hoisted_155 = { class: "fs-24 fc-1B1C1E fw-900" }
const _hoisted_156 = { class: "fs-16 mt-12" }
const _hoisted_157 = { class: "" }
const _hoisted_158 = { class: "mt-18" }
const _hoisted_159 = {
  class: "tutorial",
  style: {"margin-top":"6rem"}
}
const _hoisted_160 = { class: "select-ab-btn active color-15CE95 flex-center" }
const _hoisted_161 = { class: "text-description flex-center" }
const _hoisted_162 = { class: "select-ab-btn active color-FAA13A flex-center" }
const _hoisted_163 = { class: "text-description flex-center" }
const _hoisted_164 = { class: "select-ab-btn active color-FA8B3A flex-center" }
const _hoisted_165 = { class: "text-description flex-center" }
const _hoisted_166 = { class: "select-ab-btn active color-FA5F3A flex-center" }
const _hoisted_167 = { class: "text-description flex-center" }
const _hoisted_168 = { class: "select-ab-btn active color-FA3A3A flex-center" }
const _hoisted_169 = { class: "text-description flex-center" }
const _hoisted_170 = {
  key: 3,
  class: "card-question-padding box-card mb-32 bg-FFFFFF",
  style: {"min-height":"420px"}
}
const _hoisted_171 = { key: 0 }
const _hoisted_172 = { class: "fs-24 fc-1B1C1E fw-900" }
const _hoisted_173 = ["innerHTML"]
const _hoisted_174 = { class: "scenario-value fc-primary" }
const _hoisted_175 = { class: "scenario-label" }
const _hoisted_176 = ["onClick"]
const _hoisted_177 = { class: "value-box flex-mid" }
const _hoisted_178 = {
  key: 4,
  class: "card-question-padding box-card mb-32 bg-FFFFFF",
  style: {"min-height":"420px"}
}
const _hoisted_179 = { class: "max-width-520 mx-auto center" }
const _hoisted_180 = { class: "fs-24 fc-1B1C1E fw-900" }
const _hoisted_181 = { class: "mt-12 fs-16" }
const _hoisted_182 = { class: "fw-700" }
const _hoisted_183 = { class: "mt-40 fs-16" }
const _hoisted_184 = {
  key: 7,
  class: "remove-header remove-padding-modal"
}
const _hoisted_185 = { class: "pb-42 pt-42 pl-32 pr-52 fc-1B1C1E word-break-normal" }
const _hoisted_186 = { class: "fs-18 fw-700" }
const _hoisted_187 = { class: "fs-15 mt-20" }
const _hoisted_188 = {
  key: 0,
  class: "fs-15 fw-700 mt-20"
}
const _hoisted_189 = ["placeholder"]
const _hoisted_190 = {
  key: 2,
  class: "fs-15 fw-700 mt-16"
}
const _hoisted_191 = ["placeholder"]
const _hoisted_192 = { class: "flex-center mt-32" }
const _hoisted_193 = { class: "pb-42 pt-42 pl-32 pr-52 fc-1B1C1E word-break-normal" }
const _hoisted_194 = { class: "fs-18 fw-700" }
const _hoisted_195 = { class: "fs-15 mt-20" }
const _hoisted_196 = { class: "fs-15 fw-700 mt-20" }
const _hoisted_197 = ["placeholder"]
const _hoisted_198 = { class: "fs-15 fw-700 mt-16" }
const _hoisted_199 = ["placeholder"]
const _hoisted_200 = { class: "flex-center mt-32" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_draggable = _resolveComponent("draggable")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.step == 'get_start')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("setup.acceptableBehaviors.Setup_your_Values")), 1),
            _createElementVNode("div", _hoisted_4, [
              _createTextVNode(_toDisplayString(_ctx.$t("setup.acceptableBehaviors.Identify_areas_of_misalignment")) + " ", 1),
              _cache[46] || (_cache[46] = _createElementVNode("br", null, null, -1)),
              _cache[47] || (_cache[47] = _createElementVNode("br", null, null, -1)),
              _createTextVNode(" " + _toDisplayString(_ctx.$t("setup.acceptableBehaviors.In_each_scenario")), 1)
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", null, [
                false
                  ? (_openBlock(), _createBlock(_component_el_select, {
                      key: 0,
                      modelValue: _ctx.selectedExistsTemplate,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedExistsTemplate) = $event)),
                      class: "ep-select-44",
                      style: {"width":"42rem","margin-top":"6.4rem"},
                      placeholder: _ctx.$t('setup._Use_the_same_setup')
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.existsTemplates, (item) => {
                          return (_openBlock(), _createBlock(_component_el_option, {
                            key: item._id,
                            label: item.form_name,
                            value: item._id
                          }, null, 8, ["label", "value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["modelValue", "placeholder"]))
                  : _createCommentVNode("", true),
                (!_ctx.getStartedButtonLoading)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 1,
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.getStarted())),
                      class: "btn btn-primary"
                    }, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Get_Started")), 1),
                      (_ctx.isLightColor)
                        ? (_openBlock(), _createElementBlock("img", _hoisted_6))
                        : (_openBlock(), _createElementBlock("img", _hoisted_7))
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Get_Started")), 1),
                      _cache[48] || (_cache[48] = _createElementVNode("svg", {
                        version: "1.1",
                        id: "L9",
                        xmlns: "http://www.w3.org/2000/svg",
                        "xmlns:xlink": "http://www.w3.org/1999/xlink",
                        x: "0px",
                        y: "0px",
                        viewBox: "0 0 100 100",
                        "enable-background": "new 0 0 0 0",
                        "xml:space": "preserve",
                        class: "icon-24"
                      }, [
                        _createElementVNode("path", {
                          fill: "#aaa",
                          d: "M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                        }, [
                          _createElementVNode("animateTransform", {
                            attributeName: "transform",
                            attributeType: "XML",
                            type: "rotate",
                            dur: "1s",
                            from: "0 50 50",
                            to: "360 50 50",
                            repeatCount: "indefinite"
                          })
                        ])
                      ], -1))
                    ])),
                _createElementVNode("div", {
                  onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.closeTab && _ctx.closeTab(...args))),
                  class: "btn btn-default"
                }, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Cancel")), 1)
                ])
              ])
            ])
          ]),
          _createElementVNode("div", {
            class: "btn-close-modal",
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.closeTab && _ctx.closeTab(...args)))
          }, _cache[49] || (_cache[49] = [
            _createElementVNode("svg", {
              width: "14",
              height: "14",
              viewBox: "0 0 14 14",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg"
            }, [
              _createElementVNode("path", {
                d: "M7.0007 5.5865L11.9504 0.636719L13.3646 2.05093L8.4149 7.0007L13.3646 11.9504L11.9504 13.3646L7.0007 8.4149L2.05093 13.3646L0.636719 11.9504L5.5865 7.0007L0.636719 2.05093L2.05093 0.636719L7.0007 5.5865Z",
                fill: "black"
              })
            ], -1)
          ]))
        ]))
      : _createCommentVNode("", true),
    (_ctx.step != 'get_start' && _ctx.step != 'setup_complete')
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("div", {
                  onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.backToLastPage && _ctx.backToLastPage(...args))),
                  class: "btn-left"
                }, _cache[50] || (_cache[50] = [
                  _createElementVNode("img", {
                    src: _imports_0,
                    alt: "icons_navigate_right_black_24px",
                    class: "icon-16 animetion",
                    style: {"transform":"rotate(180deg)"}
                  }, null, -1)
                ])),
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.$t("setup.acceptableBehaviors.Setup_your_Values")), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_15, [
                (!_ctx.isFirstStep)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.gotoPreviousStep && _ctx.gotoPreviousStep(...args))),
                      class: _normalizeClass(["ml-16 btn02 btn-40 btn-previous", `${_ctx.canPreviousStep ? '' : 'disable-true'}`])
                    }, [
                      _cache[51] || (_cache[51] = _createElementVNode("img", {
                        src: _imports_0,
                        class: "icon-20 mr-12",
                        alt: "navigate_right_black_24px",
                        style: {"transform":"rotate(180deg)"}
                      }, null, -1)),
                      _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.$t("create.Previous")), 1)
                    ], 2))
                  : _createCommentVNode("", true),
                (!_ctx.isLastStep)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 1,
                      onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.gotoNextStep && _ctx.gotoNextStep(...args))),
                      class: _normalizeClass(["ml-16 btn02 btn-40 btn-next", `${
              _ctx.canNextStep && _ctx.publishSubmitStatus
                ? 'btn-primary'
                : 'disable-true'
            }`])
                    }, [
                      _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.$t("create.Next")), 1),
                      (_ctx.publishSubmitStatus)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            (_ctx.isLightColor)
                              ? (_openBlock(), _createElementBlock("img", _hoisted_18))
                              : (_openBlock(), _createElementBlock("img", _hoisted_19))
                          ], 64))
                        : (_openBlock(), _createElementBlock("svg", _hoisted_20, _cache[52] || (_cache[52] = [
                            _createElementVNode("path", {
                              fill: "#aaa",
                              d: "M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                            }, [
                              _createElementVNode("animateTransform", {
                                attributeName: "transform",
                                attributeType: "XML",
                                type: "rotate",
                                dur: "1s",
                                from: "0 50 50",
                                to: "360 50 50",
                                repeatCount: "indefinite"
                              })
                            ], -1)
                          ])))
                    ], 2))
                  : _createCommentVNode("", true),
                (_ctx.isLastStep && _ctx.publishSubmitStatus)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 2,
                      onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.publishSubmit && _ctx.publishSubmit(...args))),
                      class: "ml-16 btn02 btn-40 btn-primary"
                    }, [
                      _createElementVNode("span", _hoisted_21, _toDisplayString(_ctx.$t("create.Publish")), 1),
                      _createElementVNode("div", _hoisted_22, [
                        _createElementVNode("img", {
                          src: _imports_2,
                          class: "icon-20",
                          style: _normalizeStyle(`${_ctx.isLightColor ? 'filter: invert(1)' : ''}`),
                          alt: "icons_white_publish_24px"
                        }, null, 4)
                      ])
                    ]))
                  : (_ctx.isLastStep)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                        _createElementVNode("span", _hoisted_24, _toDisplayString(_ctx.$t("create.Publish")), 1),
                        _cache[53] || (_cache[53] = _createElementVNode("svg", {
                          width: "3rem",
                          height: "3rem",
                          version: "1.1",
                          id: "L9",
                          xmlns: "http://www.w3.org/2000/svg",
                          "xmlns:xlink": "http://www.w3.org/1999/xlink",
                          x: "0px",
                          y: "0px",
                          viewBox: "0 0 100 100",
                          "enable-background": "new 0 0 0 0",
                          "xml:space": "preserve"
                        }, [
                          _createElementVNode("path", {
                            fill: "#aaa",
                            d: "M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                          }, [
                            _createElementVNode("animateTransform", {
                              attributeName: "transform",
                              attributeType: "XML",
                              type: "rotate",
                              dur: "1s",
                              from: "0 50 50",
                              to: "360 50 50",
                              repeatCount: "indefinite"
                            })
                          ])
                        ], -1))
                      ]))
                    : _createCommentVNode("", true)
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.step != 'get_start' && _ctx.step != 'setup_complete')
      ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
          _createElementVNode("div", _hoisted_26, [
            _createElementVNode("div", _hoisted_27, [
              _createElementVNode("div", _hoisted_28, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.steps, (item, i) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: `menu_${i}`,
                    class: _normalizeClass(`menu ${_ctx.tabNextStepCls(item.step)}`),
                    style: _normalizeStyle(`display: ${item.visible === false ? 'none' : ''}`),
                    onClick: ($event: any) => (_ctx.gotoStep(item.step))
                  }, [
                    _createElementVNode("div", _hoisted_30, [
                      _createElementVNode("div", _hoisted_31, _toDisplayString(item.name), 1),
                      _createElementVNode("div", _hoisted_32, _toDisplayString(item.title), 1),
                      (item.desc)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_33, _toDisplayString(item.desc), 1))
                        : _createCommentVNode("", true)
                    ])
                  ], 14, _hoisted_29))
                }), 128))
              ]),
              _createElementVNode("div", _hoisted_34, [
                (_ctx.step == 'setup_aceetable_behaivors_scenarios')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
                      _createElementVNode("div", _hoisted_36, [
                        false
                          ? (_openBlock(), _createElementBlock("div", _hoisted_37, _toDisplayString(_ctx.$t(
                    "question.acceptableBehaviors.Evaluating_Acceptable_Behaviors"
                  )), 1))
                          : _createCommentVNode("", true),
                        false
                          ? (_openBlock(), _createElementBlock("div", _hoisted_38, [
                              _createElementVNode("div", _hoisted_39, _toDisplayString(_ctx.$t(
                      "setup.acceptableBehaviors.Review_the_acceptable_behaviors"
                    )), 1)
                            ]))
                          : _createCommentVNode("", true),
                        _createElementVNode("div", _hoisted_40, [
                          _createElementVNode("div", _hoisted_41, [
                            _createElementVNode("div", _hoisted_42, _toDisplayString(_ctx.$t("setup.acceptableBehaviors.Workplace_Scenarios")), 1),
                            _createElementVNode("div", {
                              onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.addAccepTableModalStatus = true)),
                              class: "btn btn-primary btn-40 ml-auto",
                              style: {"border-radius":"1.2rem","padding":"0 2.4rem","box-shadow":"none","font-weight":"700","font-size":"12px","line-height":"110%"}
                            }, [
                              _cache[54] || (_cache[54] = _createElementVNode("div", { class: "relative mr-14 p-2" }, [
                                _createElementVNode("svg", {
                                  width: "10",
                                  height: "10",
                                  viewBox: "0 0 10 10",
                                  fill: "none",
                                  xmlns: "http://www.w3.org/2000/svg"
                                }, [
                                  _createElementVNode("path", {
                                    d: "M4.33301 4.33398V0.333984H5.66634V4.33398H9.66634V5.66732H5.66634V9.66732H4.33301V5.66732H0.333008V4.33398H4.33301Z",
                                    fill: "white"
                                  })
                                ])
                              ], -1)),
                              _createTextVNode(" " + _toDisplayString(_ctx.$t("setup.acceptableBehaviors.Add_Scenario")), 1)
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_43, [
                            _createVNode(_component_draggable, {
                              modelValue: _ctx.acceptableBehaviorsScenario,
                              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.acceptableBehaviorsScenario) = $event)),
                              group: "people",
                              onUpdate: _ctx.updateCradList,
                              onStart: _cache[10] || (_cache[10] = ($event: any) => (_ctx.drag = true)),
                              onEnd: _cache[11] || (_cache[11] = ($event: any) => (_ctx.drag = false)),
                              "item-key": "id",
                              style: {"width":"776px","margin":"0 auto"}
                            }, {
                              item: _withCtx(({ element, index }) => [
                                _createElementVNode("div", _hoisted_44, [
                                  _createElementVNode("div", _hoisted_45, [
                                    (_openBlock(), _createElementBlock("svg", _hoisted_46, _cache[55] || (_cache[55] = [
                                      _createElementVNode("path", {
                                        d: "M4 9.25C4 8.55964 4.55964 8 5.25 8C5.94036 8 6.5 8.55964 6.5 9.25C6.5 9.94036 5.94036 10.5 5.25 10.5C4.55964 10.5 4 9.94036 4 9.25Z",
                                        fill: "#696C80"
                                      }, null, -1),
                                      _createElementVNode("path", {
                                        d: "M0 9.25C0 8.55964 0.559644 8 1.25 8C1.94036 8 2.5 8.55964 2.5 9.25C2.5 9.94036 1.94036 10.5 1.25 10.5C0.559644 10.5 0 9.94036 0 9.25Z",
                                        fill: "#696C80"
                                      }, null, -1),
                                      _createElementVNode("path", {
                                        d: "M4 5.25C4 4.55964 4.55964 4 5.25 4C5.94036 4 6.5 4.55964 6.5 5.25C6.5 5.94036 5.94036 6.5 5.25 6.5C4.55964 6.5 4 5.94036 4 5.25Z",
                                        fill: "#696C80"
                                      }, null, -1),
                                      _createElementVNode("path", {
                                        d: "M0 5.25C0 4.55964 0.559644 4 1.25 4C1.94036 4 2.5 4.55964 2.5 5.25C2.5 5.94036 1.94036 6.5 1.25 6.5C0.559644 6.5 0 5.94036 0 5.25Z",
                                        fill: "#696C80"
                                      }, null, -1),
                                      _createElementVNode("path", {
                                        d: "M4 1.25C4 0.559644 4.55964 0 5.25 0C5.94036 0 6.5 0.559644 6.5 1.25C6.5 1.94036 5.94036 2.5 5.25 2.5C4.55964 2.5 4 1.94036 4 1.25Z",
                                        fill: "#696C80"
                                      }, null, -1),
                                      _createElementVNode("path", {
                                        d: "M0 1.25C0 0.559644 0.559644 0 1.25 0C1.94036 0 2.5 0.559644 2.5 1.25C2.5 1.94036 1.94036 2.5 1.25 2.5C0.559644 2.5 0 1.94036 0 1.25Z",
                                        fill: "#696C80"
                                      }, null, -1)
                                    ])))
                                  ]),
                                  _createElementVNode("div", _hoisted_47, _toDisplayString(("0" + String(index + 1)).substring(
                              1 + index > 9 ? 1 : 0
                            )), 1),
                                  _createElementVNode("div", _hoisted_48, [
                                    _createElementVNode("div", _hoisted_49, _toDisplayString(element.label), 1)
                                  ]),
                                  _createElementVNode("div", {
                                    onClick: ($event: any) => (_ctx.removeScenario(element.id)),
                                    class: "flex-mid remove"
                                  }, _cache[56] || (_cache[56] = [
                                    _createElementVNode("svg", {
                                      width: "10",
                                      height: "10",
                                      viewBox: "0 0 10 10",
                                      fill: "none",
                                      xmlns: "http://www.w3.org/2000/svg"
                                    }, [
                                      _createElementVNode("path", {
                                        d: "M5.00047 4.05767L8.30027 0.757812L9.24306 1.70062L5.94327 5.00047L9.24306 8.30027L8.30027 9.24306L5.00047 5.94327L1.70062 9.24306L0.757812 8.30027L4.05767 5.00047L0.757812 1.70062L1.70062 0.757812L5.00047 4.05767Z",
                                        fill: "#696C80"
                                      })
                                    ], -1)
                                  ]), 8, _hoisted_50)
                                ])
                              ]),
                              _: 1
                            }, 8, ["modelValue", "onUpdate"])
                          ])
                        ])
                      ])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.step == 'Evaluating_Acceptable_Behaviors_Tutorial')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_51, [
                      _createElementVNode("div", _hoisted_52, [
                        _createElementVNode("div", _hoisted_53, _toDisplayString(_ctx.$t(
                    "question.acceptableBehaviors.Evaluating_Acceptable_Behaviors"
                  )), 1),
                        _createElementVNode("div", _hoisted_54, [
                          _createElementVNode("div", _hoisted_55, _toDisplayString(_ctx.$t(
                      "question.acceptableBehaviors.In_the_workplace_it_is_not_uncommon"
                    )), 1),
                          _createElementVNode("div", _hoisted_56, _toDisplayString(_ctx.$t(
                      "question.acceptableBehaviors.Please_provide_a_rating_for_each"
                    )), 1)
                        ]),
                        _createElementVNode("div", _hoisted_57, [
                          _createElementVNode("div", _hoisted_58, [
                            _cache[57] || (_cache[57] = _createElementVNode("div", { class: "value-box flex-mid" }, "0", -1)),
                            _createElementVNode("div", null, _toDisplayString(_ctx.$t("question.acceptableBehaviors.Acceptable")), 1)
                          ]),
                          _createElementVNode("div", _hoisted_59, _toDisplayString(_ctx.$t(
                      "question.acceptableBehaviors.This_behavior_is_acceptable"
                    )), 1),
                          _createElementVNode("div", _hoisted_60, [
                            _cache[58] || (_cache[58] = _createElementVNode("div", { class: "value-box flex-mid" }, "1", -1)),
                            _createElementVNode("div", null, _toDisplayString(_ctx.$t("question.acceptableBehaviors.Frowned_Upon")), 1)
                          ]),
                          _createElementVNode("div", _hoisted_61, _toDisplayString(_ctx.$t(
                      "question.acceptableBehaviors.This_behavior_is_not_acceptable"
                    )), 1),
                          _createElementVNode("div", _hoisted_62, [
                            _cache[59] || (_cache[59] = _createElementVNode("div", { class: "value-box flex-mid" }, "2", -1)),
                            _createElementVNode("div", null, _toDisplayString(_ctx.$t("question.acceptableBehaviors.Actively_Discouraged")), 1)
                          ]),
                          _createElementVNode("div", _hoisted_63, _toDisplayString(_ctx.$t(
                      "question.acceptableBehaviors.This_behavior_is_unacceptable_Discouraged"
                    )), 1),
                          _createElementVNode("div", _hoisted_64, [
                            _cache[60] || (_cache[60] = _createElementVNode("div", { class: "value-box flex-mid" }, "3", -1)),
                            _createElementVNode("div", null, _toDisplayString(_ctx.$t("question.acceptableBehaviors.Reprimanded")), 1)
                          ]),
                          _createElementVNode("div", _hoisted_65, _toDisplayString(_ctx.$t(
                      "question.acceptableBehaviors.This_behavior_is_unacceptable_Reprimanded"
                    )), 1),
                          _createElementVNode("div", _hoisted_66, [
                            _cache[61] || (_cache[61] = _createElementVNode("div", { class: "value-box flex-mid" }, "4", -1)),
                            _createElementVNode("div", null, _toDisplayString(_ctx.$t("question.acceptableBehaviors.Zero_Tolerance_Policy")), 1)
                          ]),
                          _createElementVNode("div", _hoisted_67, _toDisplayString(_ctx.$t(
                      "question.acceptableBehaviors.This_behavior_is_irreprehensible"
                    )), 1)
                        ])
                      ])
                    ]))
                  : (
              _ctx.step == 'Acceptable_Behaviors_Setup' && _ctx.acceptableBehaviors
            )
                    ? (_openBlock(), _createElementBlock("div", _hoisted_68, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.acceptableBehaviors.scenarios, (scenario) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: scenario.id,
                            class: "max-width-container"
                          }, [
                            (_ctx.scenarioIndexShowing == scenario.order)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_69, [
                                  _createElementVNode("div", _hoisted_70, _toDisplayString(_ctx.lang(scenario, "title")), 1),
                                  _createElementVNode("div", {
                                    class: "fs-16 mt-12",
                                    innerHTML: _ctx.lang(scenario, 'description')
                                  }, null, 8, _hoisted_71),
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(scenario.questions, (question) => {
                                    return (_openBlock(), _createElementBlock("div", {
                                      key: question.id,
                                      class: "scenario-container",
                                      style: {"margin-top":"6rem"}
                                    }, [
                                      _createElementVNode("div", null, [
                                        _createElementVNode("div", _hoisted_72, _toDisplayString(_ctx.$t("question.workStyleIdentifier.SCENARIO")) + " " + _toDisplayString(scenario.order) + "/" + _toDisplayString(Object.keys(_ctx.acceptableBehaviors.scenarios).length), 1),
                                        _createElementVNode("div", _hoisted_73, _toDisplayString(_ctx.lang(question, "title")), 1)
                                      ]),
                                      _createElementVNode("div", null, [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(question.options, (option) => {
                                          return (_openBlock(), _createElementBlock("div", {
                                            key: `${question.id}-${option.value}`,
                                            class: _normalizeClass(["select-ab-btn flex-center", [{ active: option.checked }, _ctx.optionCls(option)]]),
                                            onClick: ($event: any) => (_ctx.onClickOption(scenario, question, option))
                                          }, [
                                            _createElementVNode("div", _hoisted_75, _toDisplayString(option.value), 1),
                                            _createElementVNode("div", null, _toDisplayString(_ctx.lang(option, "label")), 1)
                                          ], 10, _hoisted_74))
                                        }), 128))
                                      ])
                                    ]))
                                  }), 128))
                                ]))
                              : _createCommentVNode("", true)
                          ]))
                        }), 128))
                      ]))
                    : (
              _ctx.step == 'Acceptable_Behaviors_Setup_AllInOne' &&
              _ctx.acceptableBehaviors
            )
                      ? (_openBlock(), _createElementBlock("div", _hoisted_76, [
                          _createElementVNode("div", _hoisted_77, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.acceptableBehaviors.scenarios, (scenario) => {
                              return (_openBlock(), _createElementBlock("div", {
                                key: scenario.id,
                                class: "scenario"
                              }, [
                                _createElementVNode("div", _hoisted_78, [
                                  _createElementVNode("div", _hoisted_79, _toDisplayString(_ctx.$t("setup.acceptableBehaviors.scenario")) + " " + _toDisplayString(scenario.order), 1),
                                  _createElementVNode("div", _hoisted_80, _toDisplayString(_ctx.lang(scenario.questions[0], "title")), 1)
                                ]),
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(scenario.questions, (question) => {
                                  return (_openBlock(), _createElementBlock("div", {
                                    key: question.id,
                                    class: "input-wrapper"
                                  }, [
                                    _createElementVNode("div", _hoisted_81, [
                                      _createElementVNode("div", _hoisted_82, [
                                        _createElementVNode("div", _hoisted_83, [
                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(question.options, (option) => {
                                            return (_openBlock(), _createElementBlock("div", {
                                              key: `${question.id}-${option.value}`,
                                              class: _normalizeClass(["choice-label", [
                            { active: option.checked },
                            _ctx.optionCls(option),
                          ]]),
                                              onClick: ($event: any) => (_ctx.onClickOption(scenario, question, option))
                                            }, [
                                              _createTextVNode(_toDisplayString(_ctx.lang(option, "label")) + " ", 1),
                                              _createElementVNode("div", _hoisted_85, [
                                                (_openBlock(), _createElementBlock("svg", {
                                                  width: "8",
                                                  height: "8",
                                                  viewBox: "0 0 8 8",
                                                  fill: "none",
                                                  xmlns: "http://www.w3.org/2000/svg",
                                                  onClick: ($event: any) => (_ctx.onClickOption(scenario, question, option))
                                                }, [
                                                  _createElementVNode("circle", {
                                                    cx: "4",
                                                    cy: "4",
                                                    r: "4",
                                                    fill: 
                                  _ctx.getOptionColorByValue(
                                    option.value,
                                    option.checked
                                  )
                                
                                                  }, null, 8, _hoisted_87)
                                                ], 8, _hoisted_86))
                                              ])
                                            ], 10, _hoisted_84))
                                          }), 128))
                                        ])
                                      ])
                                    ])
                                  ]))
                                }), 128))
                              ]))
                            }), 128))
                          ])
                        ]))
                      : _createCommentVNode("", true)
              ]),
              _cache[62] || (_cache[62] = _createElementVNode("div", null, null, -1))
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.step == 'setup_complete')
      ? (_openBlock(), _createElementBlock("div", _hoisted_88, [
          _createElementVNode("div", _hoisted_89, [
            _createElementVNode("div", _hoisted_90, _toDisplayString(_ctx.$t("setup.acceptableBehaviors.Setup_Completed")), 1),
            _createElementVNode("div", _hoisted_91, _toDisplayString(_ctx.$t("setup.acceptableBehaviors.Thank_you_for_setting_up")), 1),
            _createElementVNode("div", _hoisted_92, [
              _createElementVNode("div", null, [
                _createElementVNode("div", {
                  onClick: _cache[12] || (_cache[12] = 
//@ts-ignore
(...args) => (_ctx.closeTab && _ctx.closeTab(...args))),
                  class: "btn btn-primary"
                }, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Close_Window")), 1)
                ])
              ])
            ])
          ]),
          _createElementVNode("div", {
            class: "btn-close-modal",
            onClick: _cache[13] || (_cache[13] = 
//@ts-ignore
(...args) => (_ctx.closeTab && _ctx.closeTab(...args)))
          }, _cache[63] || (_cache[63] = [
            _createElementVNode("svg", {
              width: "14",
              height: "14",
              viewBox: "0 0 14 14",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg"
            }, [
              _createElementVNode("path", {
                d: "M7.0007 5.5865L11.9504 0.636719L13.3646 2.05093L8.4149 7.0007L13.3646 11.9504L11.9504 13.3646L7.0007 8.4149L2.05093 13.3646L0.636719 11.9504L5.5865 7.0007L0.636719 2.05093L2.05093 0.636719L7.0007 5.5865Z",
                fill: "black"
              })
            ], -1)
          ]))
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_93, [
      _createVNode(_component_el_dialog, {
        modelValue: _ctx.addAccepTableModalStatus,
        "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.addAccepTableModalStatus) = $event)),
        "custom-class": "modal",
        width: "560px"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_94, [
            _createElementVNode("div", _hoisted_95, _toDisplayString(_ctx.$t("setup.acceptableBehaviors.Add_a_workplace_scenario")), 1),
            _createElementVNode("div", _hoisted_96, _toDisplayString(_ctx.$t("setup.acceptableBehaviors.Add_a_scenario_that")), 1),
            (_ctx.isLangEnEnabled)
              ? (_openBlock(), _createElementBlock("div", _hoisted_97, [
                  _withDirectives(_createElementVNode("input", {
                    class: "input",
                    "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.valueInputEN) = $event)),
                    type: "text",
                    placeholder: _ctx.$t('setup.acceptableBehaviors.Enter_scenario_here')
                  }, null, 8, _hoisted_98), [
                    [_vModelText, _ctx.valueInputEN]
                  ]),
                  _createElementVNode("label", _hoisted_99, _toDisplayString(_ctx.$t("setup.acceptableBehaviors.New_Scenario_English")), 1)
                ]))
              : _createCommentVNode("", true),
            (_ctx.isLangThEnabled)
              ? (_openBlock(), _createElementBlock("div", _hoisted_100, [
                  _withDirectives(_createElementVNode("input", {
                    class: "input",
                    "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.valueInputTH) = $event)),
                    type: "text",
                    placeholder: _ctx.$t('setup.acceptableBehaviors.Enter_scenario_here')
                  }, null, 8, _hoisted_101), [
                    [_vModelText, _ctx.valueInputTH]
                  ]),
                  _createElementVNode("label", _hoisted_102, _toDisplayString(_ctx.$t("setup.acceptableBehaviors.New_Scenario_Thai")), 1)
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_103, [
              _createElementVNode("div", {
                class: _normalizeClass(["btn btn-48 btn-primary", { 'disable-true': !_ctx.submitNewScenarioStatusBTN }]),
                onClick: _cache[16] || (_cache[16] = ($event: any) => (_ctx.submitNewValue())),
                style: {}
              }, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Submit")), 1)
              ], 2),
              _createElementVNode("div", {
                class: "btn btn-48 btn-default",
                onClick: _cache[17] || (_cache[17] = ($event: any) => (
              (_ctx.addAccepTableModalStatus = false),
                (_ctx.valueInputEN = ''),
                (_ctx.valueInputTH = '')
            )),
                style: {}
              }, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Cancel")), 1)
              ])
            ])
          ])
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_el_dialog, {
        modelValue: _ctx.addTraitsModalStatus,
        "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.addTraitsModalStatus) = $event)),
        width: "560px"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_104, [
            _createElementVNode("div", _hoisted_105, _toDisplayString(_ctx.$t("setup.valueAndTraits.Add_a_trait")), 1),
            _createElementVNode("div", _hoisted_106, _toDisplayString(_ctx.$t("setup.valueAndTraits.Add_a_trait_to")), 1),
            _createElementVNode("div", _hoisted_107, _toDisplayString(_ctx.$t("setup.valueAndTraits.New_Trait_English")), 1),
            _withDirectives(_createElementVNode("input", {
              class: "input input-44 mt-6",
              "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.traitsInputEN) = $event)),
              type: "text",
              placeholder: _ctx.$t('setup.valueAndTraits.Enter_trait_here')
            }, null, 8, _hoisted_108), [
              [_vModelText, _ctx.traitsInputEN]
            ]),
            _createElementVNode("div", _hoisted_109, _toDisplayString(_ctx.$t("setup.valueAndTraits.New_Trait_Thai")), 1),
            _withDirectives(_createElementVNode("input", {
              class: "input input-44 mt-6",
              "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.traitsInputTH) = $event)),
              type: "text",
              placeholder: _ctx.$t('setup.valueAndTraits.Enter_trait_here')
            }, null, 8, _hoisted_110), [
              [_vModelText, _ctx.traitsInputTH]
            ]),
            _createElementVNode("div", _hoisted_111, [
              _createElementVNode("div", {
                class: "btn btn-50 btn-626262",
                onClick: _cache[21] || (_cache[21] = ($event: any) => (
              (_ctx.addTraitsModalStatus = false),
                (_ctx.traitsInputEN = ''),
                (_ctx.traitsInputTH = '')
            )),
                style: {"width":"7rem"}
              }, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Cancel")), 1)
              ]),
              _createElementVNode("div", {
                class: _normalizeClass(["btn btn-50 btn-primary ml-16", { 'disable-true': !_ctx.submitNewTraitsStatusBTN }]),
                onClick: _cache[22] || (_cache[22] = ($event: any) => (_ctx.submitNewTraits())),
                style: {"width":"11rem"}
              }, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Submit")), 1)
              ], 2)
            ])
          ])
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    false
      ? (_openBlock(), _createElementBlock("div", _hoisted_112, [
          _createElementVNode("div", _hoisted_113, [
            _createElementVNode("div", _hoisted_114, [
              (_ctx.companyLogoUrl)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    src: _ctx.companyLogoUrl,
                    alt: "Happily logo",
                    class: "company-logo-image",
                    style: {"width":"18rem"}
                  }, null, 8, _hoisted_115))
                : (_openBlock(), _createElementBlock("img", _hoisted_116)),
              _createTextVNode(" " + _toDisplayString(_ctx.step), 1)
            ]),
            (_ctx.step == 'setup_aceetable_behaivors_scenarios')
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  onClick: _cache[24] || (_cache[24] = 
//@ts-ignore
(...args) => (_ctx.getTutorail && _ctx.getTutorail(...args))),
                  class: _normalizeClass(["ml-auto btn btn-50 btn-primary", { 'disable-true': _ctx.acceptableBehaviorsScenarioDisabled }])
                }, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Next")), 1),
                  (_ctx.isLightColor || _ctx.acceptableBehaviorsScenarioDisabled)
                    ? (_openBlock(), _createElementBlock("img", _hoisted_117))
                    : (_openBlock(), _createElementBlock("img", _hoisted_118))
                ], 2))
              : _createCommentVNode("", true),
            (_ctx.step == 'Evaluating_Acceptable_Behaviors_Tutorial')
              ? (_openBlock(), _createElementBlock("div", _hoisted_119, [
                  _createElementVNode("div", {
                    onClick: _cache[25] || (_cache[25] = 
//@ts-ignore
(...args) => (_ctx.backToStart && _ctx.backToStart(...args))),
                    class: "btn btn-50 btn-primary"
                  }, [
                    (_ctx.isLightColor)
                      ? (_openBlock(), _createElementBlock("img", _hoisted_120))
                      : (_openBlock(), _createElementBlock("img", _hoisted_121)),
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Previous")), 1)
                  ]),
                  _createElementVNode("div", {
                    onClick: _cache[26] || (_cache[26] = 
//@ts-ignore
(...args) => (_ctx.startSetupValues && _ctx.startSetupValues(...args))),
                    class: "ml-auto btn btn-50 btn-primary ml-16"
                  }, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Next")), 1),
                    (_ctx.isLightColor)
                      ? (_openBlock(), _createElementBlock("img", _hoisted_122))
                      : (_openBlock(), _createElementBlock("img", _hoisted_123))
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.step == 'Acceptable_Behaviors_Setup')
              ? (_openBlock(), _createElementBlock("div", _hoisted_124, [
                  _createElementVNode("div", {
                    onClick: _cache[27] || (_cache[27] = ($event: any) => (_ctx.setupPreviousStep())),
                    class: "btn btn-50 btn-primary"
                  }, [
                    (_ctx.isLightColor)
                      ? (_openBlock(), _createElementBlock("img", _hoisted_125))
                      : (_openBlock(), _createElementBlock("img", _hoisted_126)),
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Previous")), 1)
                  ]),
                  _createElementVNode("div", {
                    onClick: _cache[28] || (_cache[28] = ($event: any) => (_ctx.setupNexStep())),
                    class: _normalizeClass(["btn btn-50 btn-primary ml-16", { 'disable-true': !_ctx.canNextStepOrig }])
                  }, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Next")), 1),
                    (_ctx.isLightColor || !_ctx.canNextStepOrig)
                      ? (_openBlock(), _createElementBlock("img", _hoisted_127))
                      : (_openBlock(), _createElementBlock("img", _hoisted_128))
                  ], 2)
                ]))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true),
    false
      ? (_openBlock(), _createElementBlock("div", _hoisted_129))
      : _createCommentVNode("", true),
    false
      ? (_openBlock(), _createElementBlock("div", _hoisted_130, [
          (_ctx.step == 'get_start')
            ? (_openBlock(), _createElementBlock("div", _hoisted_131, [
                _createElementVNode("div", _hoisted_132, [
                  _createElementVNode("div", _hoisted_133, _toDisplayString(_ctx.$t("setup.acceptableBehaviors.Setup_your_Acceptable_Behaviors")), 1),
                  _createElementVNode("div", _hoisted_134, _toDisplayString(_ctx.$t("setup.acceptableBehaviors.Identify_areas_of_misalignment")), 1),
                  _createElementVNode("div", _hoisted_135, _toDisplayString(_ctx.$t("setup.acceptableBehaviors.In_each_scenario")), 1)
                ]),
                (_ctx.existsTemplates.length)
                  ? (_openBlock(), _createBlock(_component_el_select, {
                      key: 0,
                      modelValue: _ctx.selectedExistsTemplate,
                      "onUpdate:modelValue": _cache[29] || (_cache[29] = ($event: any) => ((_ctx.selectedExistsTemplate) = $event)),
                      class: "ep-select-44",
                      style: {"width":"42rem","margin-top":"6.4rem"},
                      placeholder: _ctx.$t('setup._Use_the_same_setup')
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.existsTemplates, (item) => {
                          return (_openBlock(), _createBlock(_component_el_option, {
                            key: item._id,
                            label: item.form_name,
                            value: item._id
                          }, null, 8, ["label", "value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["modelValue", "placeholder"]))
                  : _createCommentVNode("", true),
                (!_ctx.getStartedButtonLoading)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 1,
                      onClick: _cache[30] || (_cache[30] = ($event: any) => (_ctx.getStarted())),
                      class: "mx-auto btn btn-50 btn-primary",
                      style: {"width":"16.6rem","margin-top":"6.4rem"}
                    }, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Get_Started")), 1),
                      (_ctx.isLightColor)
                        ? (_openBlock(), _createElementBlock("img", _hoisted_136))
                        : (_openBlock(), _createElementBlock("img", _hoisted_137))
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_138, [
                      _createElementVNode("div", null, _toDisplayString(_ctx.$t("btn.Get_Started")), 1),
                      _cache[64] || (_cache[64] = _createElementVNode("svg", {
                        version: "1.1",
                        id: "L9",
                        xmlns: "http://www.w3.org/2000/svg",
                        "xmlns:xlink": "http://www.w3.org/1999/xlink",
                        x: "0px",
                        y: "0px",
                        viewBox: "0 0 100 100",
                        "enable-background": "new 0 0 0 0",
                        "xml:space": "preserve"
                      }, [
                        _createElementVNode("path", {
                          fill: "#aaa",
                          d: "M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                        }, [
                          _createElementVNode("animateTransform", {
                            attributeName: "transform",
                            attributeType: "XML",
                            type: "rotate",
                            dur: "1s",
                            from: "0 50 50",
                            to: "360 50 50",
                            repeatCount: "indefinite"
                          })
                        ])
                      ], -1))
                    ]))
              ]))
            : (_ctx.step == 'setup_aceetable_behaivors_scenarios')
              ? (_openBlock(), _createElementBlock("div", _hoisted_139, [
                  _createElementVNode("div", _hoisted_140, [
                    _createElementVNode("div", _hoisted_141, _toDisplayString(_ctx.$t("question.acceptableBehaviors.Evaluating_Acceptable_Behaviors")), 1),
                    _createElementVNode("div", _hoisted_142, [
                      _createElementVNode("div", _hoisted_143, _toDisplayString(_ctx.$t("setup.acceptableBehaviors.Review_the_acceptable_behaviors")), 1)
                    ]),
                    _createElementVNode("div", _hoisted_144, [
                      _createElementVNode("div", _hoisted_145, [
                        _createElementVNode("div", _hoisted_146, _toDisplayString(_ctx.$t("setup.acceptableBehaviors.Workplace_Scenarios")), 1),
                        _createElementVNode("div", {
                          onClick: _cache[31] || (_cache[31] = ($event: any) => (_ctx.addAccepTableModalStatus = true)),
                          class: "btn btn-solid-primary btn-32 ml-auto"
                        }, [
                          _cache[65] || (_cache[65] = _createElementVNode("div", { class: "relative mr-14 p-2" }, [
                            _createElementVNode("span", { class: "absolute-mid fs-26 fw-400 pb-2" }, "+")
                          ], -1)),
                          _createTextVNode(" " + _toDisplayString(_ctx.$t("btn.ADD")), 1)
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_147, [
                        _createVNode(_component_draggable, {
                          modelValue: _ctx.acceptableBehaviorsScenario,
                          "onUpdate:modelValue": _cache[32] || (_cache[32] = ($event: any) => ((_ctx.acceptableBehaviorsScenario) = $event)),
                          group: "people",
                          onUpdate: _ctx.updateCradList,
                          onStart: _cache[33] || (_cache[33] = ($event: any) => (_ctx.drag = true)),
                          onEnd: _cache[34] || (_cache[34] = ($event: any) => (_ctx.drag = false)),
                          "item-key": "id"
                        }, {
                          item: _withCtx(({ element, index }) => [
                            _createElementVNode("div", _hoisted_148, [
                              _cache[67] || (_cache[67] = _createElementVNode("div", {
                                class: "relative",
                                style: {"width":"2.4rem"}
                              }, [
                                _createElementVNode("img", {
                                  src: _imports_6,
                                  class: "absolute-mid opa-05 pr-6",
                                  alt: "icons_more_vert_black_24dp"
                                }),
                                _createElementVNode("img", {
                                  src: _imports_6,
                                  class: "absolute-mid opa-05 pl-6",
                                  alt: "icons_more_vert_black_24dp"
                                })
                              ], -1)),
                              _createElementVNode("div", _hoisted_149, _toDisplayString(index + 1), 1),
                              _createElementVNode("div", _hoisted_150, [
                                _createElementVNode("div", _hoisted_151, _toDisplayString(element.label), 1)
                              ]),
                              _createElementVNode("div", {
                                onClick: ($event: any) => (_ctx.removeScenario(element.id)),
                                class: "flex-mid remove"
                              }, _cache[66] || (_cache[66] = [
                                _createElementVNode("img", {
                                  src: _imports_7,
                                  class: "icon-24 opa-05",
                                  alt: "icons_delete_20px@2x"
                                }, null, -1)
                              ]), 8, _hoisted_152)
                            ])
                          ]),
                          _: 1
                        }, 8, ["modelValue", "onUpdate"])
                      ])
                    ])
                  ])
                ]))
              : (_ctx.step == 'Evaluating_Acceptable_Behaviors_Tutorial')
                ? (_openBlock(), _createElementBlock("div", _hoisted_153, [
                    _createElementVNode("div", _hoisted_154, [
                      _createElementVNode("div", _hoisted_155, _toDisplayString(_ctx.$t("question.acceptableBehaviors.Evaluating_Acceptable_Behaviors")), 1),
                      _createElementVNode("div", _hoisted_156, [
                        _createElementVNode("div", _hoisted_157, _toDisplayString(_ctx.$t(
                "question.acceptableBehaviors.In_the_workplace_it_is_not_uncommon"
              )), 1),
                        _createElementVNode("div", _hoisted_158, _toDisplayString(_ctx.$t(
                "question.acceptableBehaviors.Please_provide_a_rating_for_each"
              )), 1)
                      ]),
                      _createElementVNode("div", _hoisted_159, [
                        _createElementVNode("div", _hoisted_160, [
                          _cache[68] || (_cache[68] = _createElementVNode("div", { class: "value-box flex-mid" }, "0", -1)),
                          _createElementVNode("div", null, _toDisplayString(_ctx.$t("question.acceptableBehaviors.Acceptable")), 1)
                        ]),
                        _createElementVNode("div", _hoisted_161, _toDisplayString(_ctx.$t("question.acceptableBehaviors.This_behavior_is_acceptable")), 1),
                        _createElementVNode("div", _hoisted_162, [
                          _cache[69] || (_cache[69] = _createElementVNode("div", { class: "value-box flex-mid" }, "1", -1)),
                          _createElementVNode("div", null, _toDisplayString(_ctx.$t("question.acceptableBehaviors.Frowned_Upon")), 1)
                        ]),
                        _createElementVNode("div", _hoisted_163, _toDisplayString(_ctx.$t("question.acceptableBehaviors.This_behavior_is_not_acceptable")), 1),
                        _createElementVNode("div", _hoisted_164, [
                          _cache[70] || (_cache[70] = _createElementVNode("div", { class: "value-box flex-mid" }, "2", -1)),
                          _createElementVNode("div", null, _toDisplayString(_ctx.$t("question.acceptableBehaviors.Actively_Discouraged")), 1)
                        ]),
                        _createElementVNode("div", _hoisted_165, _toDisplayString(_ctx.$t(
                "question.acceptableBehaviors.This_behavior_is_unacceptable_Discouraged"
              )), 1),
                        _createElementVNode("div", _hoisted_166, [
                          _cache[71] || (_cache[71] = _createElementVNode("div", { class: "value-box flex-mid" }, "3", -1)),
                          _createElementVNode("div", null, _toDisplayString(_ctx.$t("question.acceptableBehaviors.Reprimanded")), 1)
                        ]),
                        _createElementVNode("div", _hoisted_167, _toDisplayString(_ctx.$t(
                "question.acceptableBehaviors.This_behavior_is_unacceptable_Reprimanded"
              )), 1),
                        _createElementVNode("div", _hoisted_168, [
                          _cache[72] || (_cache[72] = _createElementVNode("div", { class: "value-box flex-mid" }, "4", -1)),
                          _createElementVNode("div", null, _toDisplayString(_ctx.$t("question.acceptableBehaviors.Zero_Tolerance_Policy")), 1)
                        ]),
                        _createElementVNode("div", _hoisted_169, _toDisplayString(_ctx.$t(
                "question.acceptableBehaviors.This_behavior_is_irreprehensible"
              )), 1)
                      ])
                    ])
                  ]))
                : (_ctx.step == 'Acceptable_Behaviors_Setup' && _ctx.acceptableBehaviors)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_170, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.acceptableBehaviors.scenarios, (scenario) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: scenario.id,
                          class: "max-width-container"
                        }, [
                          (_ctx.scenarioIndexShowing == scenario.order)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_171, [
                                _createElementVNode("div", _hoisted_172, _toDisplayString(_ctx.lang(scenario, "title")), 1),
                                _createElementVNode("div", {
                                  class: "fs-16 mt-12",
                                  innerHTML: _ctx.lang(scenario, 'description')
                                }, null, 8, _hoisted_173),
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(scenario.questions, (question) => {
                                  return (_openBlock(), _createElementBlock("div", {
                                    key: question.id,
                                    class: "scenario-container",
                                    style: {"margin-top":"6rem"}
                                  }, [
                                    _createElementVNode("div", null, [
                                      _createElementVNode("div", _hoisted_174, _toDisplayString(_ctx.$t("question.workStyleIdentifier.SCENARIO")) + " " + _toDisplayString(scenario.order) + "/" + _toDisplayString(Object.keys(_ctx.acceptableBehaviors.scenarios).length), 1),
                                      _createElementVNode("div", _hoisted_175, _toDisplayString(_ctx.lang(question, "title")), 1)
                                    ]),
                                    _createElementVNode("div", null, [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(question.options, (option) => {
                                        return (_openBlock(), _createElementBlock("div", {
                                          key: `${question.id}-${option.value}`,
                                          class: _normalizeClass(["select-ab-btn flex-center", [{ active: option.checked }, _ctx.optionCls(option)]]),
                                          onClick: ($event: any) => (_ctx.onClickOption(scenario, question, option))
                                        }, [
                                          _createElementVNode("div", _hoisted_177, _toDisplayString(option.value), 1),
                                          _createElementVNode("div", null, _toDisplayString(_ctx.lang(option, "label")), 1)
                                        ], 10, _hoisted_176))
                                      }), 128))
                                    ])
                                  ]))
                                }), 128))
                              ]))
                            : _createCommentVNode("", true)
                        ]))
                      }), 128))
                    ]))
                  : (_ctx.step == 'setup_complete')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_178, [
                        _createElementVNode("div", _hoisted_179, [
                          _createElementVNode("div", _hoisted_180, _toDisplayString(_ctx.$t("setup.Setup_Complete")), 1),
                          _createElementVNode("div", _hoisted_181, [
                            _createTextVNode(_toDisplayString(_ctx.$t("setup.Thank_you_for_setting")) + "  ", 1),
                            _createElementVNode("span", _hoisted_182, _toDisplayString(_ctx.$t("main.Acceptable_Behaviors")), 1),
                            _createTextVNode("  " + _toDisplayString(_ctx.$t("assessmentTest.section_of_the_assessment")), 1)
                          ]),
                          _createElementVNode("div", _hoisted_183, _toDisplayString(_ctx.$t("setup.You_can_now_close_this_window")), 1),
                          _createElementVNode("div", {
                            onClick: _cache[35] || (_cache[35] = ($event: any) => (_ctx.closeTab())),
                            class: "btn btn-50 btn-primary mx-auto mt-40",
                            style: {"width":"9rem"}
                          }, [
                            _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Close")), 1)
                          ])
                        ])
                      ]))
                    : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    false
      ? (_openBlock(), _createElementBlock("div", _hoisted_184, [
          _createVNode(_component_el_dialog, {
            modelValue: _ctx.addAccepTableModalStatus,
            "onUpdate:modelValue": _cache[40] || (_cache[40] = ($event: any) => ((_ctx.addAccepTableModalStatus) = $event)),
            width: "560px"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_185, [
                _createElementVNode("div", _hoisted_186, _toDisplayString(_ctx.$t("setup.acceptableBehaviors.Add_a_workplace_scenario")), 1),
                _createElementVNode("div", _hoisted_187, _toDisplayString(_ctx.$t("setup.acceptableBehaviors.Add_a_scenario_that")), 1),
                (_ctx.isLangEnEnabled)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_188, _toDisplayString(_ctx.$t("setup.acceptableBehaviors.New_Scenario_English")), 1))
                  : _createCommentVNode("", true),
                (_ctx.isLangEnEnabled)
                  ? _withDirectives((_openBlock(), _createElementBlock("input", {
                      key: 1,
                      class: "input input-44 mt-6",
                      "onUpdate:modelValue": _cache[36] || (_cache[36] = ($event: any) => ((_ctx.valueInputEN) = $event)),
                      type: "text",
                      placeholder: _ctx.$t('setup.acceptableBehaviors.Enter_scenario_here')
                    }, null, 8, _hoisted_189)), [
                      [_vModelText, _ctx.valueInputEN]
                    ])
                  : _createCommentVNode("", true),
                (_ctx.isLangThEnabled)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_190, _toDisplayString(_ctx.$t("setup.acceptableBehaviors.New_Scenario_Thai")), 1))
                  : _createCommentVNode("", true),
                (_ctx.isLangThEnabled)
                  ? _withDirectives((_openBlock(), _createElementBlock("input", {
                      key: 3,
                      class: "input input-44 mt-6",
                      "onUpdate:modelValue": _cache[37] || (_cache[37] = ($event: any) => ((_ctx.valueInputTH) = $event)),
                      type: "text",
                      placeholder: _ctx.$t('setup.acceptableBehaviors.Enter_scenario_here')
                    }, null, 8, _hoisted_191)), [
                      [_vModelText, _ctx.valueInputTH]
                    ])
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_192, [
                  _createElementVNode("div", {
                    class: "btn btn-50 btn-626262",
                    onClick: _cache[38] || (_cache[38] = ($event: any) => (
              (_ctx.addAccepTableModalStatus = false),
                (_ctx.valueInputEN = ''),
                (_ctx.valueInputTH = '')
            )),
                    style: {"width":"7rem"}
                  }, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Cancel")), 1)
                  ]),
                  _createElementVNode("div", {
                    class: _normalizeClass(["btn btn-50 btn-primary ml-16", { 'disable-true': !_ctx.submitNewScenarioStatusBTN }]),
                    onClick: _cache[39] || (_cache[39] = ($event: any) => (_ctx.submitNewValue())),
                    style: {"width":"11rem"}
                  }, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Submit")), 1)
                  ], 2)
                ])
              ])
            ]),
            _: 1
          }, 8, ["modelValue"]),
          _createVNode(_component_el_dialog, {
            modelValue: _ctx.addTraitsModalStatus,
            "onUpdate:modelValue": _cache[45] || (_cache[45] = ($event: any) => ((_ctx.addTraitsModalStatus) = $event)),
            width: "560px"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_193, [
                _createElementVNode("div", _hoisted_194, _toDisplayString(_ctx.$t("setup.valueAndTraits.Add_a_trait")), 1),
                _createElementVNode("div", _hoisted_195, _toDisplayString(_ctx.$t("setup.valueAndTraits.Add_a_trait_to")), 1),
                _createElementVNode("div", _hoisted_196, _toDisplayString(_ctx.$t("setup.valueAndTraits.New_Trait_English")), 1),
                _withDirectives(_createElementVNode("input", {
                  class: "input input-44 mt-6",
                  "onUpdate:modelValue": _cache[41] || (_cache[41] = ($event: any) => ((_ctx.traitsInputEN) = $event)),
                  type: "text",
                  placeholder: _ctx.$t('setup.valueAndTraits.Enter_trait_here')
                }, null, 8, _hoisted_197), [
                  [_vModelText, _ctx.traitsInputEN]
                ]),
                _createElementVNode("div", _hoisted_198, _toDisplayString(_ctx.$t("setup.valueAndTraits.New_Trait_Thai")), 1),
                _withDirectives(_createElementVNode("input", {
                  class: "input input-44 mt-6",
                  "onUpdate:modelValue": _cache[42] || (_cache[42] = ($event: any) => ((_ctx.traitsInputTH) = $event)),
                  type: "text",
                  placeholder: _ctx.$t('setup.valueAndTraits.Enter_trait_here')
                }, null, 8, _hoisted_199), [
                  [_vModelText, _ctx.traitsInputTH]
                ]),
                _createElementVNode("div", _hoisted_200, [
                  _createElementVNode("div", {
                    class: "btn btn-50 btn-626262",
                    onClick: _cache[43] || (_cache[43] = ($event: any) => (
              (_ctx.addTraitsModalStatus = false),
                (_ctx.traitsInputEN = ''),
                (_ctx.traitsInputTH = '')
            )),
                    style: {"width":"7rem"}
                  }, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Cancel")), 1)
                  ]),
                  _createElementVNode("div", {
                    class: _normalizeClass(["btn btn-50 btn-primary ml-16", { 'disable-true': !_ctx.submitNewTraitsStatusBTN }]),
                    onClick: _cache[44] || (_cache[44] = ($event: any) => (_ctx.submitNewTraits())),
                    style: {"width":"11rem"}
                  }, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Submit")), 1)
                  ], 2)
                ])
              ])
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}